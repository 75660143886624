<template>
  <AdminPageFrame>
      <PageTitle>
        {{ trans('PAGE_TITLE:DiscountCodes') }}
      </PageTitle>
      <t-card class="w-full">
        <div class="w-full lg:min-w-modal-gallery" v-if="discountCodesData">
          <DataTable :data="discountCodesData" :headers="tableHeaders" @edit="editDiscountCode" @delete="deleteDiscountCode">
            <template #percentDays="{item}">
              {{ item.percentDays + '%' }}
            </template>
          </DataTable>
          <t-pagination
              class="mt-3"
              variant="posts"
              :total-items="pagination.total"
              :per-page="pagination.perPage"
              :limit="5"
              v-model="currentPage"
              @change="next"
          ></t-pagination>
        </div>
      </t-card>
    <!-- Delete discountCode confirm-->
    <Modal
        v-if="isShowDeleteDiscountCodeModal"
        :show-modal="isShowDeleteDiscountCodeModal"
        @clicked="isShowDeleteDiscountCodeModal = false"
        @onConfirm="deleteDiscountCodeAction"
        :loading="discountCodeDeleting"
        :header="trans('MODAL_TITLE:Attention')"
    >
      <template v-slot:content>
        <div class="flex flex-row items-center mb-4">
          {{ trans('MODAL_DESCRIPTION:DeleteDiscountCode') }}
        </div>
      </template>
    </Modal>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import DataTable from "@/utils/table/DataTable";
import Modal from "@/utils/modal/Modal";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Pagination from "@/views/content/components/Pagination";
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DiscountCodesList",
  components: {
    AdminPageFrame,
    Button,
    DataTable,
    Modal,
    PageTitle,
    Pagination,
  },
  data() {
    return {
      tableHeaders: [
        {
          name: 'LABEL:Actions',
          value: 'actions',
        },        {
          name: 'ID',
          value: 'id'
        },
        {
          name: 'LABEL:Code',
          value: 'code'
        },
        {
          name: 'LABEL:Partner',
          value: 'partnerName',
        },
        {
          name: 'LABEL:Percent',
          value: 'percentDays',
        },
        {
          name: 'LABEL:ValidFrom',
          value: 'validFrom',
        },
        {
          name: 'LABEL:ValidTo',
          value: 'validTo'
        },
        {
          name: 'LABEL:Limit',
          value: 'limit',
        },
      ],
      currentPage: 1,
      isShowDeleteDiscountCodeModal: false,
      discountCodeToDelete: null,
    }
  },
  watch: {
    discountCodeDeleted(val) {
      if(val) {
        this.discountCodeToDelete = null;
        this.isShowDeleteDiscountCodeModal = false;
        this.currentPage = 1;
      }
    }
  },
  computed: {
    ...mapGetters('discountCodes', ['discountCodesData', 'pagination', 'discountCodeDeleting', 'discountCodeDeleted'])
  },
  methods: {
    ...mapActions('discountCodes', ['fetchShowDiscountCodeRequest', 'fetchDiscountCodesRequest', 'deleteDiscountCodeRequest']),
    next(val) {
      this.currentPage = val;
      this.fetchDiscountCodesRequest({
        page: val,
      });
    },
    deleteDiscountCode(id) {
      this.isShowDeleteDiscountCodeModal = true;
      this.discountCodeToDelete = id;
    },
    editDiscountCode(id) {
      router.push({name: 'admin-discount-codes-show', params: {id: id}})
    },
    deleteDiscountCodeAction() {
      this.deleteDiscountCodeRequest({id: this.discountCodeToDelete})
    }
  },
  mounted() {
    this.fetchDiscountCodesRequest({page: this.currentPage});
  }
}
</script>
